import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
const SEO = ({
  title,
  description,
  image,
  pathname,
  article,
  templateImage,
  product,
}) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage,
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${
          templateImage
            ? "http:" + templateImage
            : siteUrl + (image || defaultImage)
        }`,
        url: `${siteUrl}${pathname || "/"}`,
      };
      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && (
              <meta property="og:type" content="article" />
            )}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {/* {twitterUsername && (
              <meta name="twitter:creator" content={twitterUsername} />
            )} */}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            {/* E-Commerce */}
            {product?.brand && (
              <meta property="product:brand" content={product.brand} />
            )}
            {product && (
              <meta
                property="product:availability"
                content={!!product.stock ? "in stock" : "out of stock"}
              />
            )}
            {product?.price && (
              <meta property="product:price:amount" content={product.price} />
            )}
            {product && <meta property="product:condition" content="new" />}
            {product && (
              <meta property="product:price:currency" content="LKR" />
            )}
            {product?.id && (
              <meta property="product:retailer_item_id" content={product.id} />
            )}
            {product?.category && (
              <meta
                property="product:item_group_id"
                content={product.category}
              />
            )}

            {product && <meta property="product:category" content="2890" />}
          </Helmet>
        </>
      );
    }}
  />
);
export default SEO;
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
};
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
};
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`;
